import ng from 'angular';
import AcpAnytimeAlertsSubaccountModel from './acp-anytime-alerts-subaccount-model';

// @ngInject
function acpAnytimeAlertsService(
  $filter,
  $interval,
  acpAnalytics,
  nsSignal,
  nsProperty,
  nsUtil,
  webapiResource,
  nsInPageFlow,
  acpCoreDispatcher
) {
  var unverifiedSubscriptions = nsProperty.create();
  var verifiedSubscriptions = nsProperty.create();
  var alertSubscriptionAdded = nsSignal.create();
  var alertSubscriptionEdited = nsSignal.create();
  var alertSubscriptionVerified = nsSignal.create();
  var alertSubscriptionDeleted = nsSignal.create();
  var pollInitiated = nsSignal.create();
  var deviceTokens = nsProperty.create();

  var currentAddedAlert;
  var refreshPoll;

  var getAlerts = webapiResource({
    path: '/v2/alerts',
    cache: true,
    method: 'GET',
    clearSignals: [
      alertSubscriptionAdded,
      alertSubscriptionEdited,
      alertSubscriptionDeleted,
      pollInitiated
    ]
  });

  var getSubaccountAlerts = webapiResource({
    path: '/v2/subaccounts/:id/alerts',
    cache: true,
    method: 'GET',
    clearSignals: [
      alertSubscriptionAdded,
      alertSubscriptionEdited,
      alertSubscriptionDeleted,
      pollInitiated
    ]
  });

  var addAlert = webapiResource({
    path: '/v2/alerts',
    cache: false,
    method: 'POST',
    format: 'json'
  });

  var addSubaccountAlert = webapiResource({
    path: '/v2/subaccounts/:id/alerts',
    cache: false,
    method: 'POST',
    format: 'json'
  });

  var editAlert = webapiResource({
    path: '/v2/alerts',
    cache: false,
    method: 'PUT',
    format: 'json'
  });

  var editSubaccountAlert = webapiResource({
    path: '/v2/subaccounts/:id/alerts',
    cache: false,
    method: 'PUT',
    format: 'json'
  });

  var deleteAlert = webapiResource({
    path: '/v2/alerts',
    method: 'DELETE'
  });

  var deleteSubaccountAlert = webapiResource({
    path: '/v1/subaccounts/:id/alerts',
    method: 'DELETE'
  });

  var verifyAlert = webapiResource({
    path: '/v2/alerts/verify',
    method: 'POST',
    format: 'json'
  });

  var verifySubaccountAlert = webapiResource({
    path: '/v2/subaccounts/:id/alerts/verify',
    method: 'POST',
    format: 'json'
  });

  var _unverifiedSubscriptions;
  var _verifiedSubscriptions;
  var _deviceTokens;
  var pollingSubaccountParams = {};
  var subscriptions;

  var onGetAlertsSuccess = function(response, subaccountParams) {
    if (subaccountParams !== null && nsUtil.isDefined(subaccountParams)) {
      pollingSubaccountParams = ng.extend(
        {},
        pollingSubaccountParams,
        subaccountParams
      );
    }

    var totalSubscriptions = response.subscriptions.length;
    _unverifiedSubscriptions = [];
    _verifiedSubscriptions = [];
    _deviceTokens = [];

    if (totalSubscriptions > 0) {
      subscriptions = response.subscriptions.filter((subscription)=>{
        const isDevice = subscription.address_type === 'device_token';
        if (isDevice) {
          _deviceTokens.push(subscription);
        }
        return !isDevice;
      });
      subscriptions.forEach(function (subscription) {
        if (subscription.address_type === 'phone_number') {
          subscription.displayAddress = $filter('nsPhoneFormat')(
            subscription.address
          );
          subscription.verifyTextSent = false;
        } else {
          subscription.displayAddress = subscription.address;
        }
        if (subscription.verified) {
          _verifiedSubscriptions.push(subscription);
        } else {
          _unverifiedSubscriptions.push(subscription);
        }
      });

      if (_unverifiedSubscriptions.length === 0) {
        stopPolling();
      } else {
        poll(pollingSubaccountParams);
      }
    }

    unverifiedSubscriptions.set(_unverifiedSubscriptions);
    verifiedSubscriptions.set(_verifiedSubscriptions);
    deviceTokens.set(_deviceTokens);
  };

  var onGetAlertsFailure = function() {
    unverifiedSubscriptions.set(false);
    verifiedSubscriptions.set(false);
    deviceTokens.set(false);
  };

  // Polling logic for automatic refreshing of pending address verification
  var POLL_INTERVAL = 15000;

  function poll(subaccountParams) {
    if (nsUtil.isDefined(refreshPoll)) {
      return;
    }

    refreshPoll = $interval(function() {
      pollInitiated.emit(true);
      if (subaccountParams && subaccountParams.isGprSubaccount === true) {
        getSubaccountAlerts({ id: subaccountParams.accountId }).then(
          onGetAlertsSuccess,
          onGetAlertsFailure
        );
      } else {
        getAlerts().then(onGetAlertsSuccess, onGetAlertsFailure);
      }
    }, POLL_INTERVAL);
  }

  var stopPolling = function() {
    if (nsUtil.isDefined(refreshPoll)) {
      $interval.cancel(refreshPoll);
      refreshPoll = undefined;
    }
  };

  var flowTitle = function(isPushNotification, type, addressType) {
    return !isPushNotification
              ? type + ' Anytime Alert'
              : (addressType !== 'device_token' && type === 'edit') 
                ? 'Alert Details' 
                : 'Anytime Alerts';
  }

  // In-Page Flow Open, Back, and Close Actions
  var openInPageFlow = function(type, data, gaLabel, isPushNotification) {
    nsInPageFlow.open({
      template: '<acp-anytime-alerts-flow mode="{{type}}" data="data"></acp-anytime-alerts-flow>',
      flowTitle: flowTitle(isPushNotification, type, data && data.address_type),
      controller: /*@ngInject*/ function($scope) {
        $scope.type = type;
        $scope.data = data;
      },
      onBackFunction: inPageFlowBackAction(gaLabel),
      onCloseFunction: inPageFlowCloseAction
    });
  };

  // We call this if the in page flow is already open, handles passing accountData for operations on a specific account
  var continueInPageFlow = function(type, data, gaLabel, accountData) {
    nsInPageFlow.push({
      template:
        '<acp-anytime-alerts-flow mode="{{type}}" data="data" account-data="accountData"></acp-anytime-alerts-flow>',
      flowTitle: type + ' Anytime Alert',
      controller: /*@ngInject*/ function($scope) {
        $scope.type = type;
        $scope.data = data;
        $scope.accountData = accountData;
      },
      onBackFunction: inPageFlowBackAction(gaLabel),
      onCloseFunction: inPageFlowCloseAction
    });
  };

  var inPageFlowBackAction = function(gaLabel) {
    return function() {
      if (gaLabel) {
        acpAnalytics.sendEvent('ACP_AnytimeAlerts', 'NavBack_Clicked', gaLabel);
      }
    };
  };

  var inPageFlowCloseAction = function() {
    checkPhoneNumber(currentAddedAlert);
  };

  var closeInPageFlow = function(alert, flowType) {
    nsInPageFlow.close().then(function() {
      if (flowType === 'verify') {
        acpCoreDispatcher.alerts.verifyTextResent.emit(alert);
      }

      if (flowType === 'add') {
        checkPhoneNumber(alert);
      }
    });
  };

  // Check multiple phone numbers
  var modalOpened;

  var checkPhoneNumber = function(alert) {
    modalOpened = false;
    if (alert.address_type === 'phone_number' && alert.address) {
      checkSubscriptions(_verifiedSubscriptions, alert);
      if (!modalOpened) {
        checkSubscriptions(_unverifiedSubscriptions, alert);
      }
    }
  };

  var checkSubscriptions = function(subscriptions, alert) {
    for (var i = 0; i < subscriptions.length; i++) {
      if (
        subscriptions[i].address_type === 'phone_number' &&
        subscriptions[i].address !== alert.address
      ) {
        modalOpened = true;
        acpCoreDispatcher.alerts.multiplePhones.emit();
        break;
      }
    }
  };

  var possibleErrors = [
    'address.missing',
    'address.max',
    'address.format',
    'address.duplicate',
    'address_type.missing',
    'address_type.format',
    'subscribe_to_types.missing'
  ];

  var def = {
    possibleErrors: possibleErrors,
    alertSubscriptionAdded: alertSubscriptionAdded,
    alertSubscriptionEdited: alertSubscriptionEdited,
    alertSubscriptionDeleted: alertSubscriptionDeleted,
    alertSubscriptionVerified: alertSubscriptionVerified,
    unverifiedSubscriptions: unverifiedSubscriptions,
    verifiedSubscriptions: verifiedSubscriptions,
    deviceTokens: deviceTokens,
    stopPolling: stopPolling,
    listen: function () {
      return acpCoreDispatcher.login.loggedOut.onValue(stopPolling);
    },
    load: function (accountId, isGprSubaccount) {
      var subaccountAlertModel = new AcpAnytimeAlertsSubaccountModel();
      subaccountAlertModel.setSubaccountAlertInfo(accountId, isGprSubaccount);
      var subaccountParams = subaccountAlertModel.getSubaccountAlertInfo();

      if (isGprSubaccount && isGprSubaccount === true) {
        return getSubaccountAlerts({ id: subaccountParams.accountId }).then(
          function (response) {
            onGetAlertsSuccess(response, subaccountParams);
          },
          onGetAlertsFailure
        );
      } else {
        return getAlerts().then(onGetAlertsSuccess, onGetAlertsFailure);
      }
    },
    addAlert: function (data) {
      return addAlert(nsUtil.trimObjectWhitespace(data)).then(function () {
        alertSubscriptionAdded.emit();
        currentAddedAlert = data;
        return def.load();
      });
    },
    addSubaccountAlert: function (data, accountId, isGprSubaccount) {
      return addSubaccountAlert(nsUtil.trimObjectWhitespace(data)).then(
        function () {
          alertSubscriptionAdded.emit();
          currentAddedAlert = data;
          return def.load(accountId, isGprSubaccount);
        }
      );
    },
    editAlert: function (data) {
      return editAlert(data).then(function () {
        alertSubscriptionEdited.emit();
        return def.load();
      });
    },
    editSubaccountAlert: function (data, accountId, isGprSubaccount) {
      return editSubaccountAlert(data).then(function () {
        alertSubscriptionEdited.emit();
        return def.load(accountId, isGprSubaccount);
      });
    },
    deleteAlert: function (data) {
      return deleteAlert(data).then(function () {
        alertSubscriptionDeleted.emit();
        return def.load();
      });
    },
    deleteSubaccountAlert: function (data, accountId, isGprSubaccount) {
      return deleteSubaccountAlert(data).then(function () {
        alertSubscriptionDeleted.emit();
        return def.load(accountId, isGprSubaccount);
      });
    },
    verifyAlert: function (data) {
      return verifyAlert(data).then(function () {
        alertSubscriptionVerified.emit();
        return def.load();
      });
    },
    verifySubaccountAlert: function (data, accountId, isGprSubaccount) {
      return verifySubaccountAlert(data).then(function () {
        alertSubscriptionVerified.emit();
        return def.load(accountId, isGprSubaccount);
      });
    },
    openInPageFlow: function (type, data, gaLabel, isPushNotification = false) {
      return openInPageFlow(type, data, gaLabel, isPushNotification);
    },
    continueInPageFlow: function (type, data, gaLabel, accountData) {
      return continueInPageFlow(type, data, gaLabel, accountData);
    },
    closeInPageFlow: function (alert, flowType) {
      return closeInPageFlow(alert, flowType);
    },
  };

  return def;
}

export default acpAnytimeAlertsService;