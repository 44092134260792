// @ngInject
function acpAlertsSignUpModel(acpAnytimeAlertsService, acpCoreDispatcher) {
  var model = {};

  model.possibleErrors = [
    'address.missing',
    'address.max',
    'address.format',
    'address.duplicate',
    'address_type.missing',
    'address_type.format',
    'balance_daily.format',
    'balance_weekly.format',
    'budget_approaching.format',
    'budget_over.format',
    'deposit_only.format',
    'holds.format',
    'on_demand.format',
    'on_decline.format',
    'payback_rewards.format',
    'per_transaction.format',
    'types.missing',
    'device_token.missing',
    'device_token.max',
    'device_os.missing',
    'device_os.format'
  ];

  model.subscribe = function(subscription) {
    acpAnytimeAlertsService.addAlert(subscription).then(
      function() {
        acpCoreDispatcher.alerts.subscribed.success.emit({});
        acpCoreDispatcher.activation.featureEnrollment.anytimealerts.complete.emit(
          {}
        );
      },
      function(err) {
        acpCoreDispatcher.alerts.subscribed.failed.emit(err);
      }
    );
  };

  return model;
}

export default acpAlertsSignUpModel;
